import Vue from "vue";
import TokenService from "@/services/token.service";
// import MessageModel from "@/models/message.model";
import Store from "@/store";
import Router from "@/router";
import { SocketEvents } from "@/types/events.types";

const SocketState = {
  0: "CONNECTING",
  1: "OPEN",
  2: "CLOSING",
  3: "CLOSED",
  4: "RETRYING",
};

const SocketStateNumber = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  RETRYING: 4,
};

const SocketManagementService = {
  debug: process.env.VUE_APP_DEBUG == "true",
  socket: null,
  status: "CLOSED",
  connected: false,
  reconnectTimer: 0,
  reconnectTimeout: 5000,

  active: false,

  setActive(active) {
    this.active = active;
    if (active) {
      this.connect();
    } else {
      this.disconnect();
    }
  },

  async _getAccessToken() {
    TokenService.getAccessToken();

    // Check if token expired
    if (TokenService.accessToken && TokenService.accessTokenExpired) {
      // Refresh token
      return TokenService.refreshAccessToken().then(() => {
        return TokenService.accessToken;
      });
    } else {
      // Make request
      return TokenService.accessToken;
    }
  },

  // ------------------------------------ Init ------------------------------------
  // async connect (connectionCallback: () => any) {
  async connect(connectionCallback) {
    return new Promise((resolve, reject) => {
      this.disconnect();

      this._getAccessToken().then((accessToken) => {
        const path =
          process.env.VUE_APP_WEBSOCKET + "management/?token=" + accessToken + "&conference_id=" + Router.currentRoute.params.conferenceId;
        this.socket = new WebSocket(path);

        // On message
        this.socket.onmessage = (message) => {
          // console.info("[MANAGEMENT SOCKET] Message:", message);
          Vue.nextTick(() => {
            this.handleMessage(message);
          });
        };

        // On open
        this.socket.onopen = () => {
          if (this.debug) console.info("[MANAGEMENT SOCKET] Open!");
          if (connectionCallback) connectionCallback();
          this._updateState();
          Vue.prototype.$events.$emit(SocketEvents.CONNECTED);
          resolve();
        };

        // On error
        this.socket.onerror = (error) => {
          if (this.debug) console.warn("[MANAGEMENT SOCKET] Error:", error);
          Vue.prototype.$events.$emit(SocketEvents.DISCONNECTED, error);
          this._updateState();
          reject(error);
        };

        // On close
        this.socket.onclose = () => {
          if (this.debug) console.warn("[MANAGEMENT SOCKET] Closed!");
          Vue.prototype.$events.$emit(SocketEvents.DISCONNECTED);
          this._updateState();
        };

        // Call onopen directly if socket is already open
        if (WebSocket && this.socket.readyState == WebSocket.OPEN) {
          this.socket.onopen(new Event("CONNECTED", undefined));
        }

        // Start reconnect pinger
        clearInterval(this.reconnectTimer);
        this.reconnectTimer = setInterval(async () => {
          if (this.socket.readyState == WebSocket.CLOSED) {
            this._updateState(SocketStateNumber.RETRYING);
            try {
              await this.connect(connectionCallback);
            } catch (error) {
              console.log("Recconnect error", error);
            }
          }
        }, this.reconnectTimeout);
      });
    });
  },

  // ------------------------------------ Disconnect ------------------------------------
  disconnect() {
    if (this.socket) {
      if (this.debug) console.warn("[MANAGEMENT SOCKET] Disconnect");
      clearInterval(this.reconnectTimer);
      this.socket.close();
      this.socket = null;
    }
  },

  // ------------------------------------ Handle message ------------------------------------
  // handleMessage (message: any) {
  handleMessage(message) {
    const data = JSON.parse(message.data);

    // If the message is a conference event make sure its the correct one
    if (data.conference_id && Router.currentRoute.params.conferenceId && data.conference_id != Router.currentRoute.params.conferenceId) {
      return;
    }

    if (this.debug) console.log("[MANAGEMENT SOCKET] Message:", data);
    Vue.prototype.$events.$emit(SocketEvents.MESSAGE, data);

    switch (data.type) {
      case "CHAT_MESSAGE":
        if (this.debug) console.log("[MANAGEMENT SOCKET] New chat message:", data.data);
        // Store.commit("chat/addMessage", new MessageModel(data.data));
        break;
      case "CHAT_MESSAGE_DELETED":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Chat message deleted:", data.data);
        // Store.commit("chat/deleteMessage", data.data);
        break;
      case "CHAT_BANNED":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Chat banned:", data.data);
        // Store.dispatch("chat/handleChatBanned", data.data);
        break;
      case "CHAT_VIDEO_STARTED":
        if (this.debug) console.log("[SOCKET] Video chat started:", data.data);
        break;
      case "CHAT_VIDEO_ENDED":
        if (this.debug) console.log("[SOCKET] Video chat ended:", data.data);
        break;
      case "STAGE_VIEW_COUNTER":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Stage view counter change:", data.data);
        Store.dispatch("management/updateStageViewCounter", data.data);
        break;
      case "STREAM_STARTED":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Stage stream started:", data.data);
        Store.dispatch("management/handleStreamChange", data.data);
        break;
      case "STREAM_ENDED":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Stage stream ended:", data.data);
        Store.dispatch("management/handleStreamChange", data.data);
        break;
      case "SIGNIN_ON_ANOTHER_DEVICE":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Sign in on another device:", data.data);
        Store.dispatch("general/lock", data.data);
        break;
      case "POLL_PUBLISHED":
        // if (this.debug) console.log("[MANAGEMENT SOCKET] Poll published:", data.data);
        // Store.dispatch("conference/handlePollPublished", data.data);
        break;
      case "POLL_VOTED":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Poll voted:", data.data);
        // Store.dispatch("management/handlePollVoted", data.data);
        break;
      // TODO what happens when recordings change
      // case "SESSION_DOWNLOAD_COMPOSITIONS":
      //   if (this.debug) console.log("[MANAGEMENT SOCKET] Session recordings downloaded:", data.data);
      //   Store.dispatch("management/handleSessionRecordingsChange", data.data);
      //   break;
      // TODO finish this - reload the form when clickind End Session
      case "SESSION_VIDEO_CLOSED":
        if (this.debug) console.log("[SOCKET] Session video closed:", data.data);
        Store.dispatch("management/handleSessionVideoClosed", data.data);
        break;
      case "TASK_PROGRESS_EMAIL_SENDING":
        if (this.debug) console.log("[MANAGEMENT SOCKET] Task progress email sending:", data.data);
        if (data.data.emailTemplateId) {
          Store.dispatch("emailTemplate/handleEmailTemplateProgressMessage", data.data);
        } else {
          const msgTypesMap = {
            SENDING: "info",
            SENT: "success",
            FAILED: "error",
          };
          Vue.prototype.$events.$emit("nice-toast", data.data.message, msgTypesMap[data.data.type]);
        }
        break;
      case "ANNOUNCEMENT":
        Store.dispatch("management/handleAnnouncementPublished", data.data);
        break;
      default:
        if (this.debug) console.warn("Unknown socket message type:", data.type);
    }
  },

  // ------------------------------------ Send ------------------------------------
  // async send (payload: Record<string, any>): Promise<any> {
  async send(payload) {
    if (this.socket && WebSocket.OPEN && !WebSocket.CONNECTING) {
      if (this.debug) console.info("[MANAGEMENT SOCKET] Send:", payload);
      await this.socket.send(JSON.stringify(payload));
      return payload;
    } else if (WebSocket.CONNECTING) {
      // Still connecting
      console.warn("[MANAGEMENT SOCKET] Socket still connection! Failed to send:", payload);
      return new Error("Socket still connection! Failed to send");
    } else {
      // Not connected
      console.warn("[MANAGEMENT SOCKET] Socket not connected! Failed to send:", payload);
      return new Error("Socket not connected! Failed to send");
    }
  },

  // ------------------------------------ Update state in store ------------------------------------
  _updateState(state) {
    this.status =
      state != undefined ? SocketState[state] : this.socket ? SocketState[this.socket.readyState] : SocketState[SocketStateNumber.CLOSED];
    if (this.debug) console.log("[MANAGEMENT SOCKET] New state:", this.status);
    Store.commit("general/setSocketStatus", this.status);
    this.connected = this.status == SocketState[1];
  },

  // ------------------------------------ Update online status ------------------------------------
  _updateOnlineStatus(e) {
    if (e.type == "offline") {
      SocketManagementService.disconnect();
    } else if (e.type == "online" && this.active) {
      SocketManagementService.connect();
    }
  },
};

window.addEventListener("online", SocketManagementService._updateOnlineStatus);
window.addEventListener("offline", SocketManagementService._updateOnlineStatus);

export default SocketManagementService;

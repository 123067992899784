import Vue from "vue";
import "@/languages";
import Index from "./IndexView.vue";
import router from "./router";
import store from "./store";
import TokenService from "@/services/token.service";
import RequestService from "@/services/request.service";

Vue.config.productionTip = process.env.VUE_APP_PRODUCTION == "false";

// Filters
import "@/filters/DateFilter.ts";
import "@/filters/TranslateFilter";
import "@/filters/NumberFilter";
import "@/filters/CountNumberFilter";
import "@/filters/TruncateFilter";
import "@/filters/BooleanFilter";

// Directives
import "@/directives/ClickOutside";
// import '@/directives/InfiniteScroll'
import "@/directives/KeyboardEvent";

// Get general store cookies
store.dispatch("general/loadCookies");

// Set the base URL of the API
RequestService.init(process.env.VUE_APP_BASE_API);

// If token exists set header and mount the interceptor
TokenService.readFromCookies();

// Create global event but
Vue.prototype.$events = new Vue();

// Copy function
Vue.prototype.copy = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

// Globally defined components
import ManagementBreadcrumbs from "@/components/management/ManagementBreadcrumbs.vue";
Vue.component("ManagementBreadcrumbs", ManagementBreadcrumbs);

import ConfirmModal from "@/components/modals/ConfirmModal.vue";
Vue.component("ConfirmModal", ConfirmModal);

import NiceImage from "@/components/nice/NiceImage.vue";
Vue.component("NiceImage", NiceImage);

import "vue-resize/dist/vue-resize.css";
import { ResizeObserver } from "vue-resize";
Vue.component("ResizeObserver", ResizeObserver);

// Nice elements
import NiceElements from "vue-nice-elements";
import "vue-nice-elements/dist/NiceElements.css";
Vue.use(NiceElements);

// Tippy
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy, {
  directive: "tippy",
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
      flip: {
        enabled: false,
      },
      hide: {
        enabled: false,
      },
    },
  },
});
Vue.component("Tippy", TippyComponent);

// Sentry
import * as Sentry from "@sentry/vue";

import CommonService from "./services/common.service";
if (process.env.VUE_APP_SENTRY != "") {
  try {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY,
      release: process.env.VUE_APP_RELEASE,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
    });
  } catch (error) {
    console.error(error);
  }
}

CommonService.loadLanguage();

new Vue({
  router,
  store,
  render: (h) => h(Index),
}).$mount("#app");

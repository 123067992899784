import Vue from "vue";

Vue.directive("keyboard-event", {
  bind: function (el, binding, vNode) {
    // Define Handler and cache it on the element
    const handler = (e) => {
      if (e.target.nodeName != "INPUT" && e.target.nodeName != "TEXTAREA") {
        vNode.context[binding.expression](e.key);
      }
    };
    el.__vueKeyboardEvent__ = handler;

    // Add Event Listeners
    document.addEventListener("keyup", handler);
  },

  unbind: function (el) {
    // Remove Event Listeners
    document.removeEventListener("keyup", el.__vueKeyboardEvent__);
    el.__vueKeyboardEvent__ = null;
  },
});

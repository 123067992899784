<template>
  <div class="side-wrapper">
    <h1>{{ title }}</h1>
    <p v-if="!hideText">{{ text }}</p>

    <div class="row">
      <div class="col">
        <button class="btn btn-block btn-default" @click="no" v-translate>No</button>
      </div>
      <div class="col">
        <button class="btn btn-block" :class="['btn-' + type]" @click="yes" v-translate>Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ConfirmModal",

  props: {
    title: {
      type: String,
      default: Vue.prototype.$gettext("Confirm"),
    },

    text: {
      type: String,
      default: Vue.prototype.$gettext("Are you sure?"),
    },

    hideText: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "primary",
      validator: function (value) {
        return ["primary", "success", "warning", "danger"].indexOf(value) !== -1;
      },
    },
  },

  methods: {
    yes() {
      this.$emit("yes");
    },

    no() {
      this.$emit("no");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 2rem;
  font-size: 1.2em;
}
</style>

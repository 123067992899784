import Vue from "vue";

Vue.filter("CountNumberFilter", (value) => {
  const numberValue = Number(value);
  if (value !== undefined && numberValue > 0) {
    if (numberValue == 0) {
      return "0";
    } else {
      let dividor = 1;
      let abbr = "";
      if (numberValue >= 1000000000) {
        dividor = 1000000000;
        abbr = " B";
      } else if (value >= 1000000) {
        dividor = 1000000;
        abbr = " M";
      } else if (value > 1000) {
        dividor = 1000;
        abbr = " k";
      }
      if (numberValue % dividor == 0) return numberValue / dividor + abbr;
      return (numberValue / dividor).toFixed(2) + abbr;
    }
  } else {
    return value;
  }
});

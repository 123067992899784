import RequestService from "@/services/request.service";
import TokenService from "@/services/token.service";
import SocketService from "@/services/socket.service";
import { AppError } from "@/services/error.service";
import Store from "@/store";
import UserModel from "@/models/user.model";
// import qs from 'qs'

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access
   * @throws AppError
   **/
  login: async function (email: string, password: string, twofa?: string): Promise<string | AppError> {
    const request = {
      method: "post",
      url: "api/v1/token/", // process.env.VUE_APP_BASE_API
      data: {
        username: email,
        password: password,
        auth_code: twofa || undefined,
      },
    };

    try {
      const response = await RequestService.customRequest(request);
      TokenService.setAccessToken(response.access);
      TokenService.setRefreshToken(response.refresh);
      return response.access;
    } catch (error) {
      throw new AppError(error);
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove 'Authorization Bearer <token>' header from future requests.
   **/
  logout() {
    SocketService.setActive(false);
    TokenService.clear(); // Remove the token and remove Authorization header from Api Service as well
    Store.dispatch("general/clearAll");
  },

  /**
   * Get user profile data
   *
   * @returns:
   * {
   *  "id": 1,
   *  "username": "user@confiva.com",
   *  "email": "user@confiva.com",
   *  "first_name": "John",
   *  "last_name": "Doe",
   *  "profile": {
   *    "is_staff": true,
   *    "management_conferences": [ 0, 3, 59 ],
   *    "has_usable_password": true,
   *    "language": "sl",
   *    "avatar": null
   *   }
   * }
   * @throws AppError
   **/
  getUser: async function () {
    try {
      const request = {
        method: "get",
        url: "api/v1/account/",
      };
      const response = await RequestService.customRequest(request);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  /**
   *
   * Update user profile data
   *
   **/
  // updateUser: async function (first_name?: string, last_name?: string, language?: string, avatar?: File) {
  //   try {
  //     const request = {
  //       method: 'post',
  //       url: "api/v1/account/",
  //       data: {
  //         first_name,
  //         last_name,
  //         language
  //       }
  //     };
  //     const response = await RequestService.customRequest(request);
  //     return response
  //   } catch (error) {
  //     throw new AppError(error)
  //   }
  // },

  updateUser: async function (data: UserModel) {
    try {
      const path = "api/v1/account/";
      const formData = new FormData();
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("language", data.profile.language);
      formData.append("public_profile", data.profile.public_profile ? "true" : "false");
      formData.append("public_email", data.profile.email || "");
      formData.append("twitter", data.profile.twitter || "");
      formData.append("linkedin", data.profile.linkedin || "");
      formData.append("website", data.profile.website || "");
      formData.append("bio", data.profile.bio || "");
      formData.append("company_name", data.profile.company_name || "");
      formData.append("job_title", data.profile.job_title || "");
      formData.append("industry", data.profile.industry || "");
      if (data.profile.avatar instanceof File) formData.append("avatar", data.profile.avatar);
      else if (!data.profile.avatar) formData.append("avatar", "");
      const response = await RequestService.postFormData(path, formData);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  /**
   * Request password reset with user email
   *
   * @augments:
   * {
   *   "email": "info@bugkings.com"
   * }
   * @returns:
   * {
   *   "sent": true
   * }
   * @throws AppError
   **/
  requestPasswordReset: async function (email: string) {
    try {
      const response = await RequestService.post("/api/v1/account/password-reset/", { email });
      return response.data;
    } catch (error) {
      throw new AppError(error);
    }
  },

  requestEmailInvitationReset: async function (email: string) {
    try {
      const response = await RequestService.post("/api/v1/account/email-invitation-reset/", { email });
      return response.data;
    } catch (error) {
      throw new AppError(error);
    }
  },

  /**
   * Request password reset with user email
   *
   * @augments:
   * {
   *   "email": "info@bugkings.com"
   * }
   * @returns:
   * {
   *   "sent": true
   * }
   * @throws AppError
   **/
  completePasswordReset: async function (uid: string, token: string, password: string) {
    try {
      const response = await RequestService.post("/api/v1/account/password-reset/confirm/", { uid, token, password });
      return response.data;
    } catch (error) {
      throw new AppError(error);
    }
  },

  /**
   * Register new user with first_name, last_name and email
   *
   * @augments:
   * {
   *   "first_name": "John",
   *   "last_name": "Doe",
   *   "email": "john.doe@bugkings.com"
   * }
   * @returns:
   * {
   *   "created": true
   * }
   * @throws AppError
   **/
  register: async function (first_name: string, last_name: string, email: string) {
    try {
      const data = {
        first_name,
        last_name,
        email,
      };
      const response = await RequestService.post("/api/v1/account/register/", data);
      return response.data;
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default UserService;

<template>
  <NiceModal :isOpen="enabled" @close="noConfirm()">
    <ConfirmModal :title="title" :text="text" @no="noConfirm()" @yes="yesConfirm()" />
  </NiceModal>
</template>

<script>
export default {
  name: "ConfirmModalEvent",

  data() {
    return {
      eventName: "confirm-event",
      enabled: false,
      title: "",
      text: "",
      id: "",
      noFunction: null,
      yesFunction: null,
    };
  },

  methods: {
    createConfirm(id, title, message, onYes, onNo) {
      this.id = id;
      this.title = title;
      this.text = message;
      this.yesFunction = onYes;
      this.noFunction = onNo;
      this.enabled = true;
    },

    noConfirm() {
      this.enabled = false;
      this.noFunction();
    },

    yesConfirm() {
      this.enabled = false;
      this.yesFunction();
    },
  },

  mounted() {
    this.$events.$on(this.eventName, (id, title, message, onYes, onNo) => {
      this.createConfirm(id, title, message, onYes, onNo);
    });
    this.$events.$on("cancel-confirm-event", (id) => {
      if (this.id === id) {
        this.enabled = false;
      }
    });
  },

  destroyed() {
    this.$events.$off(this.eventName);
    this.$events.$off("cancel-confirm-event");
  },
};
</script>

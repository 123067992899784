import axios from "axios";
import TokenService from "@/services/token.service";
import { AppError } from "@/services/error.service";
import Store from "@/store";
import ServiceQueue from "./request-queue-service";
import SocketService from "@/services/socket.service";

const RequestService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    // axios.defaults.timeout = 5000; // 5s Timeout
    this.loadingToken = false;
  },

  // ----------------------------------------- GET -----------------------------------------
  get(path, params, headers) {
    return this.request("GET", path, null, params, headers);
  },

  // ----------------------------------------- GET BLOB -----------------------------------------
  getBlob(path, params, headers) {
    return this.request("GET", path, null, params, headers, "blob");
  },

  // ----------------------------------------- POST -----------------------------------------
  post(path, data, params, headers) {
    return this.request("POST", path, data, params, headers);
  },

  // ----------------------------------------- POST FILE -----------------------------------------
  postFile(path, file, name, headers) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    return this.request("POST", path, formData, null, headers);
  },

  // ----------------------------------------- POST FORM DATA -----------------------------------------
  postFormData(path, formData, headers) {
    return this.request("POST", path, formData, null, headers);
  },

  // ----------------------------------------- PUT -----------------------------------------
  put(path, data, params, headers) {
    return this.request("PUT", path, data, params, headers);
  },

  // ----------------------------------------- PUT FORM DATA -----------------------------------------
  putFormData(path, formData, headers) {
    return this.request("PUT", path, formData, null, headers);
  },

  // ----------------------------------------- PATCH -----------------------------------------
  patch(path, data, params, headers) {
    return this.request("PATCH", path, data, params, headers);
  },

  patchFormData(path, formData, headers) {
    return this.request("PATCH", path, formData, null, headers);
  },

  // ----------------------------------------- DELETE -----------------------------------------
  delete(path, params, headers) {
    return this.request("DELETE", path, null, params, headers);
  },

  // ----------------------------------------- CUSTOM REQUEST -----------------------------------------
  customRequest(data) {
    return this.request(data.method, data.url, data.data, data.params, data.headers, data.responseType);
  },

  // ----------------------------------------- HANDLE LIST -----------------------------------------
  _handleList(response) {
    const res = response.data.results;
    res._metadata = {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
    };
    return res;
  },

  // ----------------------------------------- GET MORE -----------------------------------------
  async getMore(url) {
    try {
      return await RequestService.get(url);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // ----------------------------------------- GET PAGE -----------------------------------------
  async getPage(path, page, filters) {
    try {
      const newFilters = {
        ...filters,
        limit: 50,
        offset: (page - 1) * 50,
      };
      return await RequestService.get(path, newFilters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // ----------------------------------------- REQUEST -----------------------------------------
  async request(method, url, data, params, headers, responseType) {
    const request = {
      method: method,
      url: url,
      data: data,
      params: params,
      headers: headers || TokenService.getHeader(),
      responseType: responseType,
    };
    return axios(request).then(
      (response) => {
        if (response.data.results != undefined) {
          return RequestService._handleList(response);
        } else {
          return response.data;
        }
      },
      (error) => {
        // Check for 401 error
        if (error && error.response && error.response.status == 401 && !url.includes("/token")) {
          // console.error("Error 401:", error);

          // User Active=False
          if (error.response.data) {
            if (error.response.data.code == "user_inactive") {
              SocketService.disconnect(); // stop cycling through WS open/close states
              // ErrorService.handleError(error); // print toast error
              window.location.href = "/user-disabled";
            }
          }

          if (this.loadingToken) {
            // push requests into queue so they can be retried after token is refreshed
            return ServiceQueue.pushRetryFn({
              reason: "waiting for token to load",
              retryFn: () => {
                return this.request(method, url, data, params, TokenService.getHeader(), responseType);
              },
            });
          }
          // Refresh token
          this.loadingToken = true;
          return TokenService.refreshAccessToken()
            .then(
              () => {
                console.info("Token refreshed!");
                // Fix original request headers
                headers = TokenService.getHeader();
                // Make original request
                const response = this.request(method, url, data, params, headers, responseType);
                ServiceQueue.retryAll();
                return response;
              },
              (refreshError) => {
                ServiceQueue.clearAll();
                console.error("Refresh token error:", refreshError);
                Store.dispatch("auth/logout");
                throw refreshError;
              }
            )
            .finally(() => {
              this.loadingToken = false;
            });
        } else {
          throw error;
        }
      }
    );
  },

  formatDate(date) {
    // YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]
    if (typeof date == "object") {
      return date.toISOString();
    } else {
      return date;
    }
  },
};

export default RequestService;

import Vue from "vue";
import Store from "@/store";

export default {
  path: "/management",
  name: "management",
  component: () => import("../views/ManagementView.vue"),
  redirect: { name: "management.conferences" },
  meta: { title: Vue.prototype.$gettext("Management") },
  children: [
    {
      path: "",
      name: "management.conferences",
      component: () => import("../views/management/ManagementConferencesView.vue"),
      meta: { title: Vue.prototype.$gettext("Conferences") },
    },
    {
      path: ":conferenceId",
      name: "management.conference",
      component: () => import("../views/management/ManagementConferenceView.vue"),
      redirect: { name: "management.conference.analytics" },
      meta: { title: Vue.prototype.$gettext("Conference") },
      beforeEnter: (to, from, next) => {
        // Check if the user has permission to access the selected item
        const checkId = to.params.conferenceId;
        Store.dispatch("management/fetchConference", checkId)
          .then(() => {
            next();
          })
          .catch(() => {
            // redirect
            next("/404");
          });
      },
      children: [
        {
          path: "analytics",
          name: "management.conference.analytics",
          component: () => import("../views/management/ManagementAnalyticsView.vue"),
          meta: { title: Vue.prototype.$gettext("Analytics") },
        },
        {
          path: "stages",
          name: "management.conference.stages",
          component: () => import("../views/management/ManagementStagesView.vue"),
          meta: { title: Vue.prototype.$gettext("Stages") },
        },
        {
          path: "showrooms",
          name: "management.conference.showrooms",
          component: () => import("../views/management/ManagementShowroomsView.vue"),
          meta: { title: Vue.prototype.$gettext("Showrooms") },
        },
        {
          path: "showroom/:showroomId",
          name: "management.conference.showroom",
          component: () => import("../views/management/ManagementShowroomEditView.vue"),
          meta: { title: Vue.prototype.$gettext("Showroom") },
          beforeEnter: (to, from, next) => {
            // Check if the user has permission to access the selected item
            const conferenceId = to.params.conferenceId;
            const checkId = to.params.showroomId;
            Store.dispatch("management/fetchConferenceShowroom", { conferenceId: conferenceId, showroomId: checkId })
              .then(() => {
                next();
              })
              .catch(() => {
                // redirect
                next("/404");
              });
          },
        },
        {
          path: "sessions",
          name: "management.conference.sessions",
          component: () => import("../views/management/ManagementSessionsView.vue"),
          meta: { title: Vue.prototype.$gettext("Sessions") },
        },
        {
          path: "session/:sessionId",
          name: "management.conference.session",
          component: () => import("../views/management/ManagementSessionEditView.vue"),
          meta: { title: Vue.prototype.$gettext("Session") },
          beforeEnter: (to, from, next) => {
            // Check if the user has permission to access the selected item
            const conferenceId = to.params.conferenceId;
            const checkId = to.params.sessionId;
            Store.dispatch("management/fetchConferenceSession", { conferenceId: conferenceId, sessionId: checkId })
              .then(() => {
                next();
              })
              .catch(() => {
                // redirect
                next("/404");
              });
          },
        },
        {
          path: "attendees",
          name: "management.conference.attendees",
          component: () => import("../views/management/ManagementAttendeesView.vue"),
          meta: { title: Vue.prototype.$gettext("Attendees"), requiresManager: true },
        },
        {
          path: "groups",
          name: "management.conference.groups",
          component: () => import("../views/management/ManagementGroupsView.vue"),
          meta: { title: Vue.prototype.$gettext("Groups") },
        },
        {
          path: "announcements",
          name: "management.conference.announcements",
          component: () => import("../views/management/ManagementAnnouncementsView.vue"),
          meta: { title: Vue.prototype.$gettext("Announcements") },
        },
        {
          path: "stage/:stageId",
          name: "management.conference.stage",
          component: () => import("../views/management/ManagementStageDashboardView.vue"),
          meta: { title: Vue.prototype.$gettext("Stage dashboard") },
          beforeEnter: (to, from, next) => {
            // Check if the user has permission to access the selected item
            const conferenceId = to.params.conferenceId;
            const checkId = to.params.stageId;
            Store.dispatch("management/fetchConferenceStage", { conferenceId: conferenceId, stageId: checkId })
              .then(() => {
                next();
              })
              .catch(() => {
                // redirect
                next("/404");
              });
          },
        },
        {
          path: "email-templates",
          name: "management.conference.email-templates",
          component: () => import("../views/management/ManagementEmailTemplatesView.vue"),
          meta: { title: Vue.prototype.$gettext("Email templates") },
        },
        {
          path: "email-template/:emailTemplateId",
          name: "management.conference.email-template",
          component: () => import("../views/management/ManagementEmailTemplateView.vue"),
          meta: { title: Vue.prototype.$gettext("Email template") },
        },
        {
          path: "settings",
          name: "management.conference.settings",
          component: () => import("../views/management/ManagementSettingsView.vue"),
          meta: { title: Vue.prototype.$gettext("Settings"), requiresManager: true },
        },
      ],
    },
  ],
};

import StorageService from "@/services/storage.service";
import CommonService from "@/services/common.service";
import UserService from "@/services/api/user.service";
import Store from "@/store";
import Router from "@/router";
import TimeService from "@/services/api/time.service";

interface SocketStatus {
  id: number;
  name: string;
  class: string;
  text: string;
  description: string;
}

interface GeneralStateI {
  socketConnected: boolean;
  socketStatus: SocketStatus;
  chatOpen: boolean;
  sidebarOpen: boolean;
  sidebarSmall: boolean;
  sidebarSmallCookieKey: string;
  sideViewWidth: number;
  sideViewWidthCookieKey: string;
  darkModeCookieKey: string;
  darkMode: boolean | null;
  clockDelta: number;
  currentDate: Date;

  microphoneCookieKey: string;
  cameraCookieKey: string;

  microphoneDeviceId: string;
  cameraDeviceId: string;

  windowWidth: number;
  windowHeight: number;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: GeneralStateI = {
  socketConnected: false,
  socketStatus: CommonService.socketState().CLOSED,
  chatOpen: false,
  sidebarOpen: false,
  sidebarSmall: false,
  sidebarSmallCookieKey: `${process.env.VUE_APP_NAME}_sidebar_small`,
  sideViewWidth: 600,
  sideViewWidthCookieKey: `${process.env.VUE_APP_NAME}_side_view_width`,
  darkModeCookieKey: `${process.env.VUE_APP_NAME}_dark_mode`,
  microphoneCookieKey: `${process.env.VUE_APP_NAME}_microphone`,
  cameraCookieKey: `${process.env.VUE_APP_NAME}_camera`,
  darkMode: null,
  clockDelta: 0,
  currentDate: null,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,

  microphoneDeviceId: "default",
  cameraDeviceId: "default",
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  clockDelta(state) {
    return state.clockDelta;
  },

  windowWidth(state) {
    return state.windowWidth;
  },

  currentDate(state) {
    return state.currentDate;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  loadCookies({ state, dispatch }) {
    state.sidebarSmall = StorageService.get(state.sidebarSmallCookieKey) == "1";
    const sideViewWidth = StorageService.get(state.sideViewWidthCookieKey);
    if (sideViewWidth) state.sideViewWidth = sideViewWidth;

    const dark = StorageService.get(state.darkModeCookieKey);
    state.darkMode = dark == "1" ? true : dark == "0" ? false : null;
    dispatch("setDarkMode");

    state.cameraDeviceId = StorageService.get(state.cameraCookieKey) ?? "default";
    state.microphoneDeviceId = StorageService.get(state.microphoneCookieKey) ?? "default";
  },

  saveToCookies({ state }) {
    StorageService.save(state.sidebarSmallCookieKey, state.sidebarSmall ? "1" : "0");
    StorageService.save(state.sideViewWidthCookieKey, state.sideViewWidth);
  },

  openChat({ commit }) {
    commit("setChatOpen", true);
  },

  closeChat({ commit }) {
    commit("setChatOpen", false);
  },

  toggleChat({ commit, state }) {
    commit("setChatOpen", !state.chatOpen);
  },

  openSidebar({ commit }) {
    commit("setSidebarOpen", true);
  },

  closeSidebar({ commit }) {
    commit("setSidebarOpen", false);
  },

  toggleSidebar({ commit, state }) {
    commit("setSidebarOpen", !state.sidebarOpen);
  },

  toggleSidebarSmall({ dispatch, commit, state }) {
    commit("setSidebarSmall", !state.sidebarSmall);
    dispatch("saveToCookies");
  },

  changeSideViewWidth({ dispatch, state }, width: number) {
    state.sideViewWidth = width;
    dispatch("saveToCookies");
  },

  clearAll() {
    Store.commit("auth/cleanStore");
    Store.commit("chat/cleanStore");
    Store.commit("conference/cleanStore");
    Store.commit("management/cleanStore");
  },

  lock() {
    UserService.logout();
    Router.push({ name: "locked" });
  },

  toggleDarkMode(context: any) {
    context.state.darkMode = !context.state.darkMode;
    StorageService.save(state.darkModeCookieKey, state.darkMode ? "1" : "0");
    context.dispatch("setDarkMode");
  },

  setDarkMode(context: any) {
    if (context.state.darkMode) {
      document.body.className = "dark";
    } else {
      document.body.className = "";
    }
  },

  setConferenceDarkMode(context: any, darkMode: boolean) {
    if (context.state.darkMode == null) {
      context.state.darkMode = darkMode;
      context.dispatch("setDarkMode");
    }
  },

  setCameraDeviceId({ state, commit }, deviceId) {
    StorageService.save(state.cameraCookieKey, deviceId);
    commit("setCameraDeviceId", deviceId);
  },

  setMicrophoneDeviceId({ state, commit }, deviceId) {
    StorageService.save(state.microphoneCookieKey, deviceId);
    commit("setMicrophoneDeviceId", deviceId);
  },

  setUserClock({ commit }) {
    TimeService.getServerClock()
      .then((response) => {
        const userTime = new Date().getTime();
        const serverTime = response.utc_ms; // UTC time in milliseconds
        const delta = serverTime - userTime;
        commit("setClockDelta", delta);
      })
      .catch(() => {
        commit("setClockDelta", 0);
      });
  },
};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  setSidebarOpen(state: GeneralStateI, isOpen: boolean) {
    state.sidebarOpen = isOpen;
  },

  setWindowWidth(state: GeneralStateI, newWidth: number) {
    state.windowWidth = newWidth;
  },

  setWindowHeight(state: GeneralStateI, newHeight: number) {
    state.windowHeight = newHeight;
  },

  setChatOpen(state: GeneralStateI, isOpen: boolean) {
    state.chatOpen = isOpen;
  },

  setSidebarSmall(state: GeneralStateI, isSmall: boolean) {
    state.sidebarSmall = isSmall;
  },

  setSocketStatus(state: GeneralStateI, status: string) {
    state.socketConnected = status == "OPEN";
    state.socketStatus = CommonService.socketState()[status] || CommonService.socketState().UNKNOWN;
  },

  setSocketConnected(state: GeneralStateI, isConnected: boolean) {
    state.socketConnected = isConnected;
  },

  setCameraDeviceId(state: GeneralStateI, deviceId) {
    state.cameraDeviceId = deviceId;
  },

  setMicrophoneDeviceId(state: GeneralStateI, deviceId) {
    state.microphoneDeviceId = deviceId;
  },

  setClockDelta(state: GeneralStateI, delta) {
    state.clockDelta = delta;
  },

  updateClock(state: GeneralStateI) {
    state.currentDate = CommonService.currentDate(state.clockDelta);
  },
};

const general = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default general;

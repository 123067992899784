import Vue from "vue";
import EmailTemplateModel from "@/models/email-template.model";
import EmailTemplatesService from "../services/api/email-template.service";
import router from "@/router";

interface EmailTemplateStoreI {
  primaryEmailTemplate: EmailTemplateModel | null;
  emailTemplate: EmailTemplateModel | null;
  emailTemplates: EmailTemplateModel[];
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: EmailTemplateStoreI = {
  primaryEmailTemplate: null,
  emailTemplate: null,
  emailTemplates: [],
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  emailTemplate: (state: EmailTemplateStoreI) => {
    return state.emailTemplate;
  },
};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  cleanStore(state: EmailTemplateStoreI) {
    state.emailTemplate = null;
    state.primaryEmailTemplate = null;
    state.emailTemplates = [];
  },

  setPrimaryEmailTemplate(state: EmailTemplateStoreI, emailTemplate: EmailTemplateModel) {
    state.primaryEmailTemplate = emailTemplate;

    if (state.emailTemplate && state.emailTemplate.id === emailTemplate.id) {
      state.emailTemplate = emailTemplate;
    }

    const index = state.emailTemplates.findIndex((template) => template.id === emailTemplate.id);
    if (index !== -1) {
      Vue.set(state.emailTemplates, index, emailTemplate);
    }
  },

  handleEmailTemplateProgressMessage(state: EmailTemplateStoreI, data) {
    const emailTemplateId = data.emailTemplateId;
    if (state.emailTemplate && state.emailTemplate.id === emailTemplateId) {
      state.emailTemplate.progressMessages.push(data);
      state.emailTemplate.sentStatus = data.sentStatus;
    }
    const emailTemplateIndex = state.emailTemplates.findIndex((template) => template.id === emailTemplateId);
    if (emailTemplateIndex !== -1) {
      state.emailTemplates[emailTemplateIndex].progressMessages.push(data);
      state.emailTemplates[emailTemplateIndex].sentStatus = data.sentStatus;
    }
  },

  setEmailTemplate(state: EmailTemplateStoreI, emailTemplate: EmailTemplateModel) {
    state.emailTemplate = emailTemplate;
  },

  setEmailTemplates(state: EmailTemplateStoreI, emailTemplates: EmailTemplateModel[]) {
    state.emailTemplates = emailTemplates;
  },

  addEmailTemplate(state: EmailTemplateStoreI, emailTemplate: EmailTemplateModel) {
    const index = state.emailTemplates.findIndex((template) => template.id === emailTemplate.id);
    if (index !== -1) {
      Vue.set(state.emailTemplates, index, emailTemplate);
    } else {
      state.emailTemplates.unshift(emailTemplate);
    }
  },

  deleteEmailTemplate(state: EmailTemplateStoreI, emailTemplateId: number) {
    if (state.emailTemplate && state.emailTemplate.id === emailTemplateId) {
      state.emailTemplate = null;
    }
    state.emailTemplates = state.emailTemplates.filter((template) => template.id !== emailTemplateId);
  },

  resetProgressMessages(state: EmailTemplateStoreI, emailTemplateId: number) {
    if (state.emailTemplate && state.emailTemplate.id === emailTemplateId) {
      state.emailTemplate.progressMessages = [];
    }
    const emailTemplateIndex = state.emailTemplates.findIndex((template) => template.id === emailTemplateId);
    if (emailTemplateIndex !== -1) {
      state.emailTemplates[emailTemplateIndex].progressMessages = [];
    }
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  async handleEmailTemplateProgressMessage({ commit }, data) {
    commit("handleEmailTemplateProgressMessage", data);
  },

  async fetchPrimaryConferenceEmailTemplate({ commit }, conferenceId: number) {
    const response = await EmailTemplatesService.getPrimaryConferenceEmailTemplate(conferenceId);
    const emailTemplate = new EmailTemplateModel(response);
    commit("setPrimaryEmailTemplate", emailTemplate);
    return emailTemplate;
  },

  async fetchConferenceEmailTemplates({ commit }, { conferenceId, params }) {
    const response = await EmailTemplatesService.getConferenceEmailTemplates(conferenceId, params);
    const emailTemplates = response.map((emailTemplate: Record<string, any>) => new EmailTemplateModel(emailTemplate));
    emailTemplates._metadata = response._metadata;
    commit("setEmailTemplates", emailTemplates);
    return emailTemplates;
  },

  async fetchConferenceEmailTemplate({ commit }, { conferenceId, emailTemplateId }) {
    const response = await EmailTemplatesService.getConferenceEmailTemplate(conferenceId, emailTemplateId);
    const emailTemplate = new EmailTemplateModel(response);
    commit("setEmailTemplate", emailTemplate);
    return emailTemplate;
  },

  async deleteConferenceEmailTemplate({ commit }, { conferenceId, emailTemplateId }) {
    await EmailTemplatesService.deleteConferenceEmailTemplate(conferenceId, emailTemplateId).catch((error) => {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      throw error;
    });
    commit("deleteEmailTemplate", null);
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Email template deleted"), "success");
    router.push({ name: "management.conference.email-templates" });
  },

  async setPrimaryConferenceEmailTemplate({ commit }, { conferenceId, emailTemplateId }) {
    const response = await EmailTemplatesService.setConferenceEmailTemplateAsPrimary(conferenceId, emailTemplateId);
    const emailTemplate = new EmailTemplateModel(response);
    commit("setPrimaryEmailTemplate", emailTemplate);
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Email template set as active"), "success");
    return emailTemplate;
  },

  async createNewConferenceEmailTemplate({ commit }, { conferenceId }) {
    const response = await EmailTemplatesService.createNewConferenceEmailTemplate(conferenceId);
    const emailTemplate = new EmailTemplateModel(response);
    router.push({ name: "management.conference.email-template", params: { emailTemplateId: emailTemplate.id.toString() } });
    return emailTemplate;
  },

  async duplicateConferenceEmailTemplate({ commit }, { conferenceId, emailTemplateId }) {
    const response = await EmailTemplatesService.duplicateConferenceEmailTemplate(conferenceId, emailTemplateId);
    const emailTemplate = new EmailTemplateModel(response);
    commit("addEmailTemplate", emailTemplate);
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Email template duplicated"), "success");
    return emailTemplate;
  },

  async updateConferenceEmailTemplateSchedule({ commit }, { conferenceId, emailTemplate }) {
    const response = await EmailTemplatesService.updateConferenceEmailTemplate(
      conferenceId,
      emailTemplate.id,
      EmailTemplatesService._prepareEmailTemplateDataForSchedule(emailTemplate)
    );
    const emailTemplateResponse = new EmailTemplateModel(response);
    commit("setEmailTemplate", emailTemplateResponse);
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Email template updated"), "success");
    return emailTemplateResponse;
  },

  async updateConferenceEmailTemplateEdit({ commit }, { conferenceId, emailTemplate }) {
    const response = await EmailTemplatesService.updateConferenceEmailTemplate(
      conferenceId,
      emailTemplate.id,
      EmailTemplatesService._prepareEmailTemplateDataForEdit(emailTemplate)
    );
    const emailTemplateResponse = new EmailTemplateModel(response);
    commit("setEmailTemplate", emailTemplateResponse);
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Email template updated"), "success");
    return emailTemplateResponse;
  },

  async sendConferenceEmailTemplate({ commit }, { conferenceId, emailTemplateId }) {
    commit("resetProgressMessages", emailTemplateId);
    await EmailTemplatesService.sendConferenceEmailTemplate(conferenceId, emailTemplateId);
  },
};

// ---------------------------------------------------------------- Module ----------------------------------------------------------------
const emailTemplate = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default emailTemplate;

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import chat from "@/store/chat.store";
import conference from "@/store/conference.store";
import general from "@/store/general.store";
import management from "@/store/management.store";
import auth from "@/store/auth.store";
import meetings from "@/store/meetings.store";
import dashboard from "@/store/dashboard.store";
import emailTemplate from "./email-template.store";
import polls from "@/store/polls.store";
import notifications from "@/store/notifications.store";

export default new Vuex.Store({
  modules: {
    chat,
    conference,
    auth,
    general,
    management,
    meetings,
    dashboard,
    emailTemplate,
    polls,
    notifications,
  },
});

enum Events {
  toast = "nice-toast",
  notification = "nice-notification",
}

enum SocketEvents {
  CONNECTED = "socket-connected",
  DISCONNECTED = "socket-disconnected",
  MESSAGE = "socket-message",
}

enum NotificationTypes {
  DEFAULT = "default",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export { Events, SocketEvents, NotificationTypes };

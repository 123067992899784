<template>
  <div class="nice-component nice-file" :class="{ disabled: disabled, 'no-margin': noMargin }">
    <div class="input-group">
      <div class="nice-label" v-if="title">{{ title }}<span v-if="required">*</span></div>
      <div class="form-group">
        <input
          :name="name"
          type="file"
          ref="fileInput"
          class="file-input"
          :accept="accept ? accept : defaultImagePreviewExtensions"
          :required="required"
          :disabled="disabled"
          @change="filesChange($event.target.name, $event.target.files)"
          @dragenter="dragenter"
          @dragleave="dragleave"
          @dragend="dragend"
        />
        <img class="preview-image" :src="image.image || image" v-if="image && hasValidFileExtension(image, true)" />
        <div class="drop-area" v-if="image && !hasValidFileExtension(image, true)">{{ filename }}</div>
        <div class="drop-area" v-if="!image" :class="{ dragging: dragging }">
          {{ placeholder ? $gettext(placeholder) : $gettext("Click to select file") }}
        </div>
        <button type="button" class="btn-default btn-close" @click="clear" v-if="image">
          <svg viewBox="0 0 24 24">
            <path
              d="M13.4 12l5.3-5.3c0.4-0.4 0.4-1 0-1.4s-1-0.4-1.4 0l-5.3 5.3-5.3-5.3c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4
              l5.3 5.3-5.3 5.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3
              l5.3-5.3 5.3 5.3c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4l-5.3-5.3z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NiceImage",

  props: {
    value: [String, File],
    title: String,
    placeholder: String,
    accept: String,
    required: Boolean,
    disabled: Boolean,
    noMargin: Boolean,
    canEdit: Boolean,
  },

  computed: {
    filename() {
      if (!this.image) return "";
      if (this.image instanceof File && !this.image.name) return "";

      const file =
        this.image instanceof File
          ? this.image.name
            ? this.image.name
            : "" // when adding new image
          : new URL(this.image).pathname; // when using existing image

      return file.split("/").pop().split("?")[0].split("#")[0];
    },
  },

  data() {
    return {
      defaultImagePreviewExtensions: ".jpg, .jpeg, .gif, .png",
      dragging: false,
      files: null,
      file: null,
      image: null,
      name: null,
    };
  },

  methods: {
    filesChange(name, files) {
      this.dragging = false;
      this.files = files;
      if (this.files.length > 0) {
        this.file = this.files[0];
        this.name = this.files[0].name;

        const reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target.result;
          this.file.image = this.image;
          this.handleChange(this.file);
        };
        reader.readAsDataURL(this.file);
      }
    },

    clear() {
      const input = this.$refs.fileInput;
      input.type = "file";
      input.value = "";
      this.files = null;
      this.file = null;
      this.name = null;
      this.image = null;
      this.$emit("input", null);
      this.$emit("change", null);
    },

    handleChange(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },

    getExtension(value) {
      if (!value) return false;
      if (value instanceof File && !value.name) return false;

      const extension =
        value instanceof File
          ? value.name
            ? value.name
            : "" // when adding new image
          : new URL(value).pathname; // when using existing image

      return extension.split(".").pop();
    },

    hasValidFileExtension(value, checkIsImage = false) {
      const extension = this.getExtension(value);
      if (!extension) return false;

      const accepted =
        this.accept && !checkIsImage
          ? this.accept // check for images and other allowed file extensions
          : this.defaultImagePreviewExtensions; // check only images

      if (accepted.replace(/ /g, "").replace(/\./g, "").split(",").includes(extension)) {
        return true;
      } else {
        return false;
      }
    },

    dragenter() {
      this.dragging = true;
    },

    dragleave() {
      this.dragging = false;
    },

    dragend() {
      this.dragging = false;
    },
  },

  watch: {
    value() {
      this.hasValidFileExtension(this.value) ? (this.image = this.value) : this.clear();
    },
  },

  mounted() {
    this.image = this.value;
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  background: var(--nice-bg);
  border-radius: var(--nice-border-radius);
  border: var(--nice-border);
  height: 100px;

  .preview-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    // object-fit: cover;
    object-fit: contain;
    border-radius: var(--border-radius);
  }

  .drop-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    // border-radius: var(--nice-border-radius);
    // background: var(--nice-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 1em;
    background: var(--nice-bg);
    border-radius: var(--nice-border-radius);
    border: var(--nice-border);
    font-family: inherit;

    &.dragging {
      border: 2px dashed var(--nice-base-color);
    }

    &:focus {
      outline: 0;
      background: var(--nice-bg-focus);
    }

    &:hover {
      background: var(--nice-bg-hover);
    }
  }

  .file-input {
    min-height: 100px;
    width: 100%;
    border: 0 none;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    background: transparent;

    text-align: center;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .file-name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    background: transparent;
    border: 0 none;
    z-index: 4;
  }
}

.validation-error {
  label {
    color: var(--nice-error-color);
  }
  .error-message {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.8em;
    color: var(--nice-error-color);
  }
}
</style>

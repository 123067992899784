import ManagementService from "@/services/api/management.service";

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state = {
  generalAnalytics: {},

  startTime: null,
  endTime: null,

  selectedStage: null,
  selectedShowroom: null,
  selectedSession: null,

  exportOptions: {},

  stagesVisits: [],
  showroomsVisits: [],
  sessionsVisits: [],

  stageAnalytics: {},
  showroomAnalytics: {},
  sessionAnalytics: {},
  meetingsAnalytics: {},
  attendeesAnalytics: {},
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  setStartTime(state, time) {
    state.startTime = time;
  },

  setEndTime(state, time) {
    state.endTime = time;
  },

  setGeneralAnalytics(state, generalAnalytics) {
    state.generalAnalytics = generalAnalytics;
  },

  setExportOptions(state, options) {
    state.exportOptions = options;
  },

  setStagesVisits(state, stagesVisits) {
    stagesVisits.sort((a, b) => b.total_visits - a.total_visits);
    state.stagesVisits = stagesVisits;
  },

  setShowroomsVisits(state, showroomsVisits) {
    showroomsVisits.sort((a, b) => b.total_visits - a.total_visits);
    state.showroomsVisits = showroomsVisits;
  },

  setSessionsVisits(state, sessionsVisits) {
    sessionsVisits.sort((a, b) => b.total_visits - a.total_visits);
    state.sessionsVisits = sessionsVisits;
  },

  setStageAnalytics(state, stageAnalytics) {
    state.stageAnalytics = stageAnalytics;
  },

  setShowroomAnalytics(state, showroomAnalytics) {
    state.showroomAnalytics = showroomAnalytics;
  },

  setSessionAnalytics(state, sessionAnalytics) {
    state.sessionAnalytics = sessionAnalytics;
  },

  setMeetingsAnalytics(state, meetingsAnalytics) {
    state.meetingsAnalytics = meetingsAnalytics;
  },

  setAttendeesAnalytics(state, attendeesAnalytics) {
    state.attendeesAnalytics = attendeesAnalytics;
  },

  setSelectedStage(state, stageId) {
    state.selectedStage = stageId;
  },

  setSelectedShowroom(state, showroomId) {
    state.selectedShowroom = showroomId;
  },

  setSelectedSession(state, sessionId) {
    state.selectedSession = sessionId;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  async fetchGeneralAnalytics(context, conferenceId) {
    try {
      const response = await ManagementService.getConferenceAnalytics(conferenceId, {
        start_time: context.state.startTime,
        end_time: context.state.endTime,
      });
      context.commit("setGeneralAnalytics", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchExportOptions(context, conferenceId) {
    try {
      const response = await ManagementService.getDashboardExportOptions(conferenceId);
      context.commit("setExportOptions", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchStagesVisits(context, conferenceId) {
    try {
      const response = await ManagementService.getDashboardStages(conferenceId);
      context.commit("setStagesVisits", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchShowroomsVisits(context, conferenceId) {
    try {
      const response = await ManagementService.getDashboardShowrooms(conferenceId);
      context.commit("setShowroomsVisits", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchSessionsVisits(context, conferenceId) {
    try {
      const response = await ManagementService.getDashboardSessions(conferenceId);
      context.commit("setSessionsVisits", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchStageAnalytics(context, { conferenceId, stageId }) {
    try {
      const response = await ManagementService.getDashboardStage(conferenceId, stageId, {
        start_time: context.state.startTime,
        end_time: context.state.endTime,
      });
      context.commit("setStageAnalytics", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchShowroomAnalytics(context, { conferenceId, showroomId }) {
    try {
      const response = await ManagementService.getDashboardShowroom(conferenceId, showroomId, {
        start_time: context.state.startTime,
        end_time: context.state.endTime,
      });
      context.commit("setShowroomAnalytics", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchSessionAnalytics(context, { conferenceId, sessionId }) {
    try {
      const response = await ManagementService.getDashboardSession(conferenceId, sessionId, {
        start_time: context.state.startTime,
        end_time: context.state.endTime,
      });
      context.commit("setSessionAnalytics", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchMeetingsAnalytics(context, conferenceId) {
    try {
      const response = await ManagementService.getDashboardMeetings(conferenceId);
      context.commit("setMeetingsAnalytics", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchAttendeesAnalytics(context, conferenceId) {
    try {
      const response = await ManagementService.getDashboardAttendees(conferenceId, {
        start_time: context.state.startTime,
        end_time: context.state.endTime,
      });
      context.commit("setAttendeesAnalytics", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

// ---------------------------------------------------------------- Model ----------------------------------------------------------------
const dashboard = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dashboard;

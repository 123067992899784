import RequestService from "@/services/request.service";
import ErrorService from "@/services/error.service";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TimeService = {
  getServerClock: async function () {
    try {
      const path = "/api/v1/account/time/";
      return await RequestService.get(path);
    } catch (error) {
      ErrorService.handleError(error);
    }
  },
};

export default TimeService;

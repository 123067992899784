import Vue from "vue";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/public/LoginView.vue"),
    meta: {
      title: Vue.prototype.$gettext("Login"),
      public: true,
    },
  },
  {
    path: "/invite/:invitationKey?",
    name: "invitation",
    component: () => import("../views/public/ConferenceInviteView.vue"),
    meta: {
      title: Vue.prototype.$gettext("Invitation"),
      public: true,
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/public/ErrorView.vue"),
    meta: {
      title: Vue.prototype.$gettext("Error"),
      public: true,
    },
  },
  {
    path: "/locked",
    name: "locked",
    component: () => import("../views/public/LockedView.vue"),
    meta: {
      title: Vue.prototype.$gettext("Locked"),
      public: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/public/NotFoundView.vue"),
    meta: {
      title: Vue.prototype.$gettext("Not found"),
      public: true,
    },
  },
  {
    path: "/forgot-password",
    name: "password-forgot",
    component: () => import("../views/public/PasswordForgot.vue"),
    meta: {
      title: Vue.prototype.$gettext("Forgot password"),
      public: true,
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("../views/public/PasswordReset.vue"),
    meta: {
      title: Vue.prototype.$gettext("Password reset"),
      public: true,
    },
  },
  {
    path: "/invitation-forgot",
    name: "invitation-forgot",
    component: () => import("../views/public/InvitationEmailResetView.vue"),
    meta: {
      title: Vue.prototype.$gettext("Invitation email reset"),
      public: true,
    },
  },
  {
    path: "/user-disabled",
    name: "user-disabled",
    component: () => import("../views/public/UserDisabled.vue"),
    meta: {
      title: Vue.prototype.$gettext("User disabled"),
      public: true,
    },
  },
  { path: "/not-supported" }, // Ignore or pass on to server
  // {
  //   path: '/not-supported',
  //   name: 'not-supported',
  //   component: () => import('../views/public/NotSupportedView.vue'),
  //   meta: {
  //     title: "Not supported",
  //     public: true
  //   }
  // },
];

import Vue from "vue";
import Router from "@/router";
import UserModel from "@/models/user.model";
import UserService from "@/services/api/user.service";
import TokenService from "@/services/token.service";

interface AuthStateI {
  user: UserModel | null;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: AuthStateI = {
  user: null,
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  loggedIn: () => {
    return TokenService.accessToken ? true : false;
  },
  isStaff: (state) => {
    return state.user?.profile.is_staff;
  },

  isConferenceManager: (state) => (conferenceId: number) => {
    if (state.user != null) {
      const conf = state.user?.profile.management_conferences.filter((x) => x.id == conferenceId);
      if (conf.length > 0) {
        return conf[0].role === "OWNER";
      }
    }
    return false;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  async login({ dispatch }, { email, password }) {
    try {
      const token = await UserService.login(email, password);
      await dispatch("getUser");
      // console.log(Router.currentRoute.query)
      // Router.push(Router.currentRoute.query["redirect"] || '/');
      Router.push("/");
      return token;
    } catch (error) {
      throw error;
    }
  },

  async getUser({ state, dispatch }) {
    try {
      const response = await UserService.getUser();
      state.user = response;
      return response;
    } catch (error) {
      dispatch("logout");
      throw error;
    }
  },

  async updateUser({ state }, form: UserModel) {
    try {
      const response = await UserService.updateUser(form);
      state.user = response;
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("User '%{ first_name }' was updated."), { first_name: form.first_name }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  logout() {
    console.warn("Logout.");
    UserService.logout();
    // Router.push('/login');
    // Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Logged out."), "error");
    window.location.href = "/login";
  },
};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  cleanStore(state: AuthStateI) {
    state.user = null;
  },

  updateUser(state: AuthStateI, attendeeData: any) {
    const newUser: any = state.user;
    newUser.first_name = attendeeData.first_name;
    newUser.last_name = attendeeData.last_name;
    newUser.email = attendeeData.email;
    newUser.profile.email = attendeeData.email;
    state.user = newUser;
  },
};

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default auth;

export default class AttendeeGroupModel {
  id: number;
  name: string;
  color: string;
  is_organizer: boolean;

  stages: number[] = [];
  showrooms: number[] = [];
  sessions: number[] = [];

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name;
    this.color = data.color || "";
    this.is_organizer = data.is_organizer;

    this.stages = data.stages || [];
    this.showrooms = data.showrooms || [];
    this.sessions = data.sessions || [];
  }

  // constructor(id: number, name: string, color: string) {
  //   this.id = id;
  //   this.name = name;
  //   this.color = color || "";
  // }
}

import CommonService from "@/services/common.service";

enum ConferenceStatuses {
  scheduled = "scheduled", // The event's Date Start is in the future. When events are generated, or when you manually enter an event, by default, its status is set to Scheduled.
  canceled = "canceled", // The event has been cancelled.
  inProgress = "inProgress", // The event has begun, but is not yet completed.
  onHold = "onHold", // The event has been placed on hold before it began.
  stopped = "stopped", // The event began but was stopped before completion.
  completed = "completed", // The event has completed.
}

export class ConferenceSettingsModel {
  send_primary_email_immediately: boolean;

  constructor(data: Record<string, any>) {
    if (data) {
      this.send_primary_email_immediately = data.send_primary_email_immediately;
    }
  }
}

export default class ConferenceModel {
  id: number;
  name: string;
  description: string;
  long_description: string;
  cover_photo?: string;
  thumbnail_photo?: string;
  color?: string;
  style?: Record<string, any>;
  chat_channel: string;
  end_date: Date;
  start_date: Date;
  status: ConferenceStatuses;
  custom_logo: string;
  enable_chat: boolean;
  dark_mode: boolean;

  has_showrooms: boolean;
  has_sessions: boolean;
  has_stages: boolean;
  has_attendees: boolean;
  has_meetings: boolean;
  // has_meeting_attendee: boolean;

  language: string;

  settings: ConferenceSettingsModel;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name || "No title!"; // don't translate here
    this.description = data.description;
    this.long_description = data.long_description;
    this.cover_photo = data.cover_photo;
    this.thumbnail_photo = data.thumbnail_photo;
    this.color = data.color;
    this.chat_channel = data.chat_channel;
    this.end_date = new Date(data.end_date);
    this.start_date = new Date(data.start_date);
    this.has_showrooms = data.has_showrooms;
    this.has_sessions = data.has_sessions;
    this.has_stages = data.has_stages;
    this.has_attendees = data.has_attendees;
    this.has_meetings = data.has_meetings;
    // this.has_meeting_attendee = data.has_meeting_attendee;
    this.custom_logo = data.custom_logo;
    this.enable_chat = data.enable_chat;
    this.dark_mode = data.dark_mode;
    this.style = this.handleConferenceStyle();
    this.status = this.handleConferenceStatus();
    this.language = data.language;
    this.settings = new ConferenceSettingsModel(data.settings);
  }

  // constructor(id: number, name: string, description: string, cover_photo: string, chat_channel: string, end_date: Date, start_date: Date) {
  //   this.id = id;
  //   this.name = name || "No title!"; // don't translate here
  //   this.description = description;
  //   this.cover_photo = cover_photo;
  //   this.chat_channel = chat_channel;
  //   this.end_date = end_date;
  //   this.start_date = start_date;
  // }

  handleConferenceStyle(): Record<string, any> {
    if (this.color) {
      return {
        "--base-color": this.color,
        "--base-color-lighter": CommonService.lightenDarkenColor(this.color, 5),
        "--base-color-darker": CommonService.lightenDarkenColor(this.color, -5),
        "--base-color-darkest": CommonService.lightenDarkenColor(this.color, -25),
        "--base-color-opacity-03": this.color + "03",
        "--base-color-opacity-06": this.color + "06",
        "--nice-base-color": this.color,
        "--nice-base-color-lighter": CommonService.lightenDarkenColor(this.color, 5),
        "--nice-base-color-darker": CommonService.lightenDarkenColor(this.color, -5),
      };
    } else {
      return {};
    }
  }

  handleConferenceStatus(): ConferenceStatuses {
    if (CommonService.isBetween(this.start_date, this.end_date)) {
      return ConferenceStatuses.inProgress;
    } else if (CommonService.isBefore(this.start_date)) {
      return ConferenceStatuses.scheduled;
    } else if (CommonService.isAfter(this.end_date)) {
      return ConferenceStatuses.completed;
    } else {
      return ConferenceStatuses.scheduled;
    }
  }
}

import Vue from "vue";

Vue.filter("TruncateFilter", (value: any, length: number) => {
  if (value) {
    const clamp = "...";
    return value.length > length ? value.slice(0, length) + clamp : value;
  } else {
    return value;
  }
});

import Vue from "vue";

const ErrorService = {
  errorCodes: {
    "-1": Vue.prototype.$gettext("Something went wrong"),
    400: Vue.prototype.$gettext("Bad request"),
    401: Vue.prototype.$gettext("Unauthorized"),
    402: Vue.prototype.$gettext("Payment required"),
    403: Vue.prototype.$gettext("Forbidden"),
    404: Vue.prototype.$gettext("Not found"),
    405: Vue.prototype.$gettext("Method not allowed"),
    406: Vue.prototype.$gettext("Not acceptable"),
    407: Vue.prototype.$gettext("Proxy authentication required"),
    500: Vue.prototype.$gettext("Internal server error"),
    502: Vue.prototype.$gettext("Bad gateway"),
    503: Vue.prototype.$gettext("Service unavailable"),
    504: Vue.prototype.$gettext("Gateway timeout"),
    ECONNABORTED: Vue.prototype.$gettext("Request timeout!"),
    ERR_EMPTY_RESPONSE: Vue.prototype.$gettext("Empty response"),
    ERR_CONNECTION_REFUSED: Vue.prototype.$gettext("Connection refused"),
    invalid_grant: Vue.prototype.$gettext("Invalid credentials given."),
    "Authentication credentials were not provided.": Vue.prototype.$gettext("Authentication credentials were not provided."),
    1: Vue.prototype.$gettext("This email is not registered within our system."),
    2: Vue.prototype.$gettext("Password reset token is invalid or already expired."),
    100: Vue.prototype.$gettext("Invalid or expired reset token!"),
    cta_already_claimed: Vue.prototype.$gettext("Cannot claim CTA reward. You have already registered interest."),
    cta_no_quantity_left: Vue.prototype.$gettext("Cannot claim CTA reward. Max number of registrants reached."),
    account_does_not_exist: Vue.prototype.$gettext("You do not have an account."),
    user_inactive: Vue.prototype.$gettext("User is inactive"),
  },

  handleError: (error) => {
    const e = ErrorService.getError(error);
    // Vue.prototype.$events.$emit('nice-notification', "ERROR", e.message, "Error " + e.code);
    Vue.prototype.$events.$emit("nice-toast", e.message, "error");
  },

  getError: (error) => {
    console.error(error);

    if (error.response && (error.response.status == 400 || error.response.status == 401 || error.response.status == 406)) {
      // Read from error codes
      if (ErrorService.errorCodes[error.response.data.error_code]) {
        return {
          code: error.response.status,
          message: ErrorService.errorCodes[error.response.data.error_code],
        };
      } else if (ErrorService.errorCodes[error.response.data.error]) {
        return {
          code: error.response.status,
          message: ErrorService.errorCodes[error.response.data.error],
        };
      } else if (error.response.data.message) {
        return {
          code: error.response.status,
          message: error.response.data.message,
        };
      } else if (error.response.data.detail) {
        return {
          code: error.response.status,
          message: error.response.data.detail,
        };
      } else {
        return {
          code: error.response.status,
          message: error.response.data,
        };
      }
    } else if (error.response && ErrorService.errorCodes[error.response.status]) {
      // Read from error codes
      return {
        code: error.response.status,
        message: ErrorService.errorCodes[error.response.status],
      };
    } else if (error.response && error.response.data.detail) {
      // Read from response detail
      return {
        code: error.response.status,
        message: error.response.data.detail,
      };
    } else if (error.response && error.response.data.message) {
      // Read from response message
      return {
        code: error.response.status,
        message: error.response.data.message,
      };
    } else if (error.code && ErrorService.errorCodes[error.code]) {
      // Request timeout
      return {
        code: error.code,
        message: ErrorService.errorCodes[error.code],
      };
    } else if (error instanceof Error) {
      return {
        code: "",
        message: error.toString(),
      };
    } else if (typeof error == "string") {
      return {
        code: "",
        message: error,
      };
    } else if (Array.isArray(error.message)) {
      // read from response message when array
      return {
        code: error.errorCode || "", // TODO add codes from the error response array
        message: error.message.map((el) => el.message).join("\n"),
      };
    } else if (error.response && error.response.status == 404) {
      return {
        code: "404",
        message: Vue.prototype.$gettext("Not found"),
      };
    } else if (typeof error.message == "string") {
      // read from response message when string
      return {
        code: error.errorCode || "",
        message: error.message,
      };
    } else {
      // Unknown error
      return {
        code: "",
        message: Vue.prototype.$gettext("Unknown error"),
      };
    }
  },

  // handle the error when we route to the same page more than once
  // "Uncaught (in promise) Error: Avoided redundant navigation to current location:"
  // https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
  handleRouteErrors: function (error) {
    // ignore the error regarding routing to the same page we're already on
    if (
      error instanceof Error &&
      error.name === "NavigationDuplicated" &&
      error.message.includes("Avoided redundant navigation to current location")
    ) {
      return;
    }

    // handle other errors
    this.getError(error);
  },
};

class AppError {
  // extends Error {
  constructor(error) {
    // super()
    // Error.captureStackTrace(this, this.constructor);
    const err = ErrorService.getError(error);
    this.name = "AppError";
    this.message = err.message;
    this.errorCode = err.code;
    this.raw = error;
  }
}

export default ErrorService;
export { ErrorService, AppError };

import Vue from "vue";
import GetTextPlugin from "vue-gettext";
import translations from "../static/locale/translation.json";

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_US: "English",
    sl_SI: "Slovenščina",
    hr_HR: "Hrvatski",
    es_ES: "Español",
    de_DE: "Deutsch",
    it_IT: "Italiano",
    pt_PT: "Português",
    hu_HU: "Magyar",
    sv_SE: "Svenska",
  },
  // defaultLanguage: "sl_SI",
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace("_", "-");
      },
    },
  },
  translations: translations,
  silent: true,
});

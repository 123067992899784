import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

const StorageService = {
  dev: process.env.VUE_APP_PRODUCTION == "false",
  cookieSettings: {
    domain: "", // ".confiva.com",
    path: "/",
    secure: false,
  },

  getExpireTime(): Date {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date;
  },

  removeAll() {
    localStorage.clear();
  },

  remove(key: string) {
    key = this._handleKey(key);
    Vue.$cookies.remove(key, StorageService.cookieSettings.path, StorageService.cookieSettings.domain);
    // localStorage.removeItem(key);
  },

  save(key: string, value?: string, expire?: Date) {
    key = this._handleKey(key);
    if (value === undefined) {
      StorageService.remove(key);
    } else {
      Vue.$cookies.set(
        key,
        value,
        expire || StorageService.getExpireTime(),
        StorageService.cookieSettings.path,
        StorageService.cookieSettings.domain,
        StorageService.cookieSettings.secure
      );
      // localStorage.setItem(key, value);
    }
  },

  get(key: string) {
    key = this._handleKey(key);
    return Vue.$cookies.get(key);
    // return localStorage.getItem(key);
  },

  _handleKey(key: string) {
    if (this.dev) key += "_dev";
    return key;
  },
};

export default StorageService;

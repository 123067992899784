import Vue from "vue";

Vue.filter("NumberFilter", (value) => {
  const numberValue = Number(value);
  if (value !== undefined) {
    if (numberValue == 0) {
      return "Free";
    } else {
      return numberValue.toLocaleString(undefined, { minimumFractionDigits: 2 }) + " €";
    }
  } else {
    return value;
  }
});

enum SentStatus {
  Scheduled = "SCHEDULED",
  Sent = "SENT",
  Sending = "SENDING",
  Failed = "FAILED",
  NotSent = "NOT_SENT",
}

class EmailTemplateProgressMessage {
  emailTemplateId: number;
  message: string;
  summary: string;
  type: string;
  time: Date;
}

export default class EmailTemplateModel {
  id: number;
  name: string;
  filters: Record<string, any>;
  sentStatus: SentStatus;
  sentTime: Date;
  isPrimary: boolean;

  progressMessages: EmailTemplateProgressMessage[];

  subject: string;
  logo: File | string | null;
  color: string | null;
  backgroundColor: string | null;
  introText: string | null;
  mainText: string | null;
  ctaText: string | null;
  outroText: string | null;
  actionUrl: string | null;
  redirectUrl: string | null;

  addIcsAttachment: boolean;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name;
    this.filters = data.filters ?? { groups: [] };
    this.sentStatus = data.sent_status;
    this.sentTime = data.sent_time ? new Date(data.sent_time) : null;
    this.subject = data.subject;
    this.logo = data.logo;
    this.color = data.color;
    this.backgroundColor = data.background_color;
    this.introText = data.intro_text;
    this.mainText = data.main_text;
    this.ctaText = data.cta_text;
    this.outroText = data.outro_text;
    this.isPrimary = data.is_primary ?? false;
    this.addIcsAttachment = data.add_ics_attachment ?? true;
    this.progressMessages = [];
    this.actionUrl = data.action_url;
    this.redirectUrl = data.redirect_url;
  }
}

export default class NotificationModel {
  id: number;
  notificationType: string;
  updated: Date;
  data: any;
  objectId: number;
  conferenceId: number;
  custom: boolean;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.updated = new Date(data.updated);
    this.notificationType = data.notification_type;
    this.data = data.data;
    this.objectId = data.object_id;
    this.conferenceId = data.conference;
    this.custom = data.custom ?? false;

    if (this.notificationType === "ANNOUNCEMENT") {
      this.data.description = this.data.description.length > 60 ? this.data.description.slice(0, 57) + "..." : this.data.description;
    }
  }
}

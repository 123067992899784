import EmailTemplateModel from "@/models/email-template.model";
import { AppError } from "@/services/error.service";
import RequestService from "@/services/request.service";

const EmailTemplatesService = {
  getPrimaryConferenceEmailTemplate: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/primary/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getConferenceEmailTemplates: async function (conferenceId: number, params: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/`;
      return await RequestService.get(path, params);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getConferenceEmailTemplate: async function (conferenceId: number, emailTemplateId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/${emailTemplateId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  setConferenceEmailTemplateAsPrimary: async function (conferenceId: number, emailTemplateId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/${emailTemplateId}/set-primary/`;
      const response = await RequestService.post(path);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteConferenceEmailTemplate: async function (conferenceId: number, emailTemplateId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/${emailTemplateId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  createNewConferenceEmailTemplate: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/create-new/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  duplicateConferenceEmailTemplate: async function (conferenceId: number, emailTemplateId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/${emailTemplateId}/duplicate/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  sendConferenceEmailTemplate: async function (conferenceId: number, emailTemplateId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/${emailTemplateId}/send/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateConferenceEmailTemplate: async function (conferenceId: number, emailTemplateId: number, data: FormData) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/email-templates/${emailTemplateId}/`;
      return await RequestService.patchFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareEmailTemplateDataForSchedule: function (emailTemplate: EmailTemplateModel) {
    const data = new FormData();
    data.append("name", emailTemplate.name);
    if (emailTemplate.isPrimary || emailTemplate.sentStatus == "SENT") return data;

    if (emailTemplate.sentTime) {
      data.append("sent_time", new Date(emailTemplate.sentTime).toISOString());
    } else {
      data.append("sent_time", "");
    }
    const filters_obj = {};
    if (emailTemplate.filters.groups) filters_obj["groups"] = emailTemplate.filters.groups;
    if (emailTemplate.filters.invitation_sent_status) filters_obj["invitation_sent_status"] = emailTemplate.filters.invitation_sent_status;
    if (emailTemplate.filters.registration_status) filters_obj["registration_status"] = emailTemplate.filters.registration_status;
    data.append("filters", JSON.stringify(filters_obj));
    return data;
  },

  _prepareEmailTemplateDataForEdit: function (emailTemplate: EmailTemplateModel) {
    const data = new FormData();
    data.append("subject", emailTemplate.subject);
    data.append("color", emailTemplate.color || "");
    data.append("background_color", emailTemplate.backgroundColor || "");
    data.append("intro_text", emailTemplate.introText || "");
    data.append("main_text", emailTemplate.mainText || "");
    data.append("cta_text", emailTemplate.ctaText || "");
    data.append("outro_text", emailTemplate.outroText || "");
    data.append("add_ics_attachment", emailTemplate.addIcsAttachment ? "true" : "false");
    data.append("action_url", emailTemplate.actionUrl || "");
    data.append("redirect_url", emailTemplate.redirectUrl || "");
    if (emailTemplate.logo && emailTemplate.logo instanceof File) data.append("logo", emailTemplate.logo);
    if (!emailTemplate.logo) data.append("logo", "");
    return data;
  },
};

export default EmailTemplatesService;

import RequestService from "@/services/request.service";
import { AppError } from "@/services/error.service";

const MeetingService = {
  getAll: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  get: async function (conferenceId: number, meetingId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/${meetingId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getReads: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/last-reads/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startNew: async function (conferenceId: number, attendee: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/new-meeting/`;
      const data = {
        attendees: [attendee],
      };
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startNewMultiple: async function (conferenceId: number, attendees: number[]) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/new-meeting/`;
      const data = {
        attendees: attendees,
      };
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startVideo: async function (conferenceId: number, attendee: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/new-video/`;
      const data = {
        attendee: attendee,
      };
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  endVideo: async function (conferenceId: number, attendee: number, type: string) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/meetings/stop-video/`;
      const data = {
        attendee: attendee,
        type: type,
      };
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getAttachments: async function (chatRouteKey: string) {
    try {
      const path = `/api/v1/chats/${chatRouteKey}/files/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareChatAttachment(file: any) {
    const data = new FormData();
    data.append("name", file.name);
    if (file.file instanceof File) data.append("file", file.file);
    else if (!file.file) data.append("file", "");
    return data;
  },

  uploadAttachment: async function (chatRouteKey: string, file: any) {
    try {
      const path = `/api/v1/chats/${chatRouteKey}/files/`;
      const data = this._prepareChatAttachment(file);
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default MeetingService;

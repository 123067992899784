class VideoModel {
  quality: string;
  file: URL;

  constructor(data: Record<string, any>) {
    this.quality = data.quality;
    this.file = new URL(data.file);
  }
}

export default class RecordingModel {
  id: number;
  name: string;
  thumbnail?: string;
  created: Date;
  link: URL;
  source?: string;
  mux_playback_id: string;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name;
    this.thumbnail = data.thumbnail;
    this.created = new Date(data.created);
    this.link = data.link;
    this.source = data.source;
    this.mux_playback_id = data.mux_playback_id;
  }
}

export { RecordingModel, VideoModel };

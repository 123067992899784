import Vue from "vue";

interface ChatStateI {
  hasMoreMessages: boolean;
  currentChatRouteKey: string;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: ChatStateI = {
  hasMoreMessages: false,
  currentChatRouteKey: "",
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  cleanStore(state: ChatStateI) {
    state.hasMoreMessages = false;
  },

  setCurrentChatRouteKey(state: ChatStateI, route: string) {
    state.currentChatRouteKey = route;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  handleChatBanned() {
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("You are banned from the chat!"), "error");
  },
};

// ---------------------------------------------------------------- Module ----------------------------------------------------------------
const chat = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default chat;

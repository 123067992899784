import Vue from "vue";

Vue.filter("DateFilter", (value: any, noTime: boolean, showSeconds: boolean, timeOnly: boolean) => {
  if (value) {
    const date = new Date(value);
    const hh = date.getHours();
    let mm = date.getMinutes().toString();
    let ss = date.getSeconds().toString();
    const DD = date.getDate();
    const MM = date.getMonth() + 1; // January is 0!
    const YYYY = date.getFullYear();

    if (parseInt(mm) < 10) {
      mm = "0" + mm;
    }
    if (parseInt(ss) < 10) {
      ss = "0" + ss;
    }

    const dateFormat = DD + "." + MM + "." + YYYY;
    const timeFormat = hh + ":" + mm;

    if (timeOnly) {
      if (showSeconds) {
        return timeFormat + ":" + ss;
      } else {
        return timeFormat;
      }
    } else {
      if (noTime) {
        return dateFormat;
      } else {
        if (showSeconds) {
          return dateFormat + " • " + timeFormat + ":" + ss;
        } else {
          return dateFormat + " • " + timeFormat;
        }
      }
    }
  } else {
    return "";
  }
});

export default class StreamModel {
  is_live: boolean;
  key: string;
  rtmp_endpoint: string;
  link: URL;
  started_at?: Date;
  latency_mode: string;
  name?: string;
  source?: string;

  constructor(data: Record<string, any>) {
    this.link = data.link;
    this.key = data.key;
    this.rtmp_endpoint = data.rtmp_endpoint;
    this.is_live = data.is_live;
    this.started_at = data.started_at;
    this.latency_mode = data.latency_mode;
    this.name = data.name;
    this.source = data.source;
  }

  // constructor(link: URL, started_at: Date, is_live: boolean) {
  //   this.link = link;
  //   this.is_live = is_live;
  //   this.started_at = started_at;
  // }
}

import { AppError } from "../error.service";
import RequestService from "../request.service";

const NotificationsService = {
  getConferenceNotifications: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/notifications/`;
      const response = await RequestService.get(path, filters);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  getConferenceNotificationsUnread: async function (conferenceId: number, from: Date | null) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/notifications/unread/`;
      const filters = {};
      if (from != null) {
        filters["from"] = from.toISOString();
      }
      const response = await RequestService.get(path, filters);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  readNotification: async function (conferenceId: number, notificationId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/notifications/${notificationId}/mark-read/`;
      const response = await RequestService.post(path);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  readNotificationAll: async function (conferenceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/notifications/mark-read/`;
      const response = await RequestService.post(path);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default NotificationsService;

import StorageService from "@/services/storage.service";
import axios from "axios";
import Store from "@/store";

const TokenService = {
  debug: false, // process.env.VUE_APP_DEBUG == "true",
  accessToken: null,
  accessTokenExpired: false,
  accessTokenExpireTimeout: 0,
  accessTokenCookieName: `${process.env.VUE_APP_NAME}_access_token`,

  refreshToken: null,
  refreshTokenExpired: false,
  refreshTokenExpireTimeout: 0,
  refreshTokenCookieName: `${process.env.VUE_APP_NAME}_refresh_token`,

  getHeader() {
    let headers = {
      "Content-Type": "application/json",
    };
    if (this.accessToken) {
      headers = {
        Authorization: `Bearer ${this.getAccessToken()}`,
        "Content-Type": "application/json",
      };
    }

    // console.log(headers);
    return headers;
  },

  readFromCookies() {
    this.getAccessToken();
    this.getRefreshToken();

    if (!this.accessToken) {
      if (this.debug) console.log("[TOKEN] No token saved :(");
    }
  },

  hasToken() {
    return this.accessToken != null;
  },

  getAccessToken() {
    this.accessTokenExpired = false;
    this.accessToken = StorageService.get(this.accessTokenCookieName);
    if (this.accessToken) {
      const accessTokenDecoded = JSON.parse(atob((this.accessToken || "").split(".")[1]));
      const accessTokenExpireDate = new Date(accessTokenDecoded.exp * 1000);
      const accessTokenExpireMilliseconds = accessTokenExpireDate.getTime() - new Date().getTime();

      if (this.debug) {
        console.log("[TOKEN] Access token", {
          decoded: accessTokenDecoded,
          expireDate: accessTokenExpireDate.toString(),
          expireDateMilliseconds: accessTokenExpireMilliseconds,
        });
      }

      if (accessTokenExpireMilliseconds <= 0) {
        if (this.debug) console.error("[TOKEN] Access token already expired!");
        this.accessTokenExpired = true;
      }

      clearTimeout(this.accessTokenExpireTimeout || undefined);
      this.accessTokenExpireTimeout = setTimeout(() => {
        if (this.debug) console.error("[TOKEN] Access token expired!");
        this.accessTokenExpired = true;
      }, accessTokenExpireMilliseconds);
    }
    return this.accessToken;
  },

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
    const expireTime = new Date();
    expireTime.setMonth(expireTime.getMonth() + 3);
    StorageService.save(this.accessTokenCookieName, this.accessToken, expireTime);
    this.getAccessToken();
  },

  removeAccessToken() {
    this.accessToken = null;
    StorageService.remove(this.accessTokenCookieName);
    clearTimeout(this.accessTokenExpireTimeout || undefined);
  },

  getRefreshToken() {
    this.refreshTokenExpired = false;
    this.refreshToken = StorageService.get(this.refreshTokenCookieName);
    if (this.refreshToken) {
      const refreshTokenDecoded = JSON.parse(atob((this.refreshToken || "").split(".")[1]));
      const refreshTokenExpireDate = new Date(refreshTokenDecoded.exp * 1000);
      const refreshTokenExpireMilliseconds = refreshTokenExpireDate.getTime() - new Date().getTime();

      if (this.debug) {
        console.log("[TOKEN] Refresh token", {
          decoded: refreshTokenDecoded,
          expireDate: refreshTokenExpireDate.toString(),
          expireDateMilliseconds: refreshTokenExpireMilliseconds,
        });
      }

      if (refreshTokenExpireMilliseconds <= 0) {
        if (this.debug) console.error("[TOKEN] Refresh token expired!");
        this.refreshTokenExpired = true;
      }

      // clearTimeout(this.refreshTokenExpireTimeout);
      // this.refreshTokenExpireTimeout = setTimeout(() => {
      //   if (this.debug) console.error("Refresh token expired!");
      //   this.refreshTokenExpired = true;
      // }, refreshTokenExpireMilliseconds);
    }
    return this.refreshToken;
  },

  setRefreshToken(refreshToken) {
    this.refreshToken = refreshToken;
    const expireTime = new Date();
    expireTime.setMonth(expireTime.getMonth() + 3);
    StorageService.save(this.refreshTokenCookieName, this.refreshToken, expireTime);
    this.getRefreshToken();
  },

  removeRefreshToken() {
    this.refreshToken = null;
    StorageService.remove(this.refreshTokenCookieName);
    clearTimeout(this.refreshTokenExpireTimeout || undefined);
  },

  async refreshAccessToken() {
    const request = {
      method: "post",
      url: "api/v1/token/refresh/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        refresh: TokenService.getRefreshToken(),
      },
    };

    try {
      const response = await axios(request);
      if (this.debug) console.log("[TOKEN] Token refreshed:", response.data);
      TokenService.setAccessToken(response.data.access);
      TokenService.setRefreshToken(response.data.refresh);
      return response.data.access;
    } catch (error) {
      Store.dispatch("auth/logout");
      throw error;
    }
  },

  clear() {
    this.removeAccessToken();
    this.removeRefreshToken();
  },
};

export default TokenService;

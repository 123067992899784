import RequestService from "@/services/request.service";
import { AppError } from "@/services/error.service";
import SocketService from "../socket.service";
import SocketManagementService from "../socket-management.service";
import MessageModel from "@/models/message.model";

const ChatService = {
  getHistoryDetails: async function (channelName: string) {
    try {
      const path = `/api/v1/chats/${channelName}/history/chat-details/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getHistory: async function (channelName: string, filters: any = {}) {
    try {
      const path = `/api/v1/chats/${channelName}/history/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getPinnedHistory: async function (channelName: string) {
    try {
      const path = `/api/v1/chats/${channelName}/history/`;
      return await RequestService.get(path, {
        pinned: true,
      });
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteChatMessage: async function (channelName: string, messageId: number) {
    try {
      const path = `/api/v1/chats/${channelName}/history/${messageId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  toggleLikeChatMessage: async function (channelName: string, messageId: number) {
    try {
      const path = `/api/v1/chats/${channelName}/history/${messageId}/like/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  readChatMessages: async function (channelName: string) {
    try {
      const path = `/api/v1/chats/${channelName}/history/read/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  sendToChat: async function (payload: Record<string, any>) {
    if (SocketService.connected) {
      return await SocketService.send(payload);
    } else if (SocketManagementService.connected) {
      return await SocketManagementService.send(payload);
    } else {
      console.warn("[CHAT] No socket connected!");
      return null;
    }
  },

  updateChatMessage: async function (channelName: string, messageId: number, message: MessageModel) {
    try {
      const path = `/api/v1/chats/${channelName}/history/${messageId}/`;
      return await RequestService.put(path, message);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startWherebyVideo: async function (channelName: string) {
    try {
      const path = `/api/v1/chats/${channelName}/history/start-whereby/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startVideo: async function (channelName: string) {
    try {
      const path = `/api/v1/chats/${channelName}/history/notify-start-video/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  stopVideo: async function (channelName: string, type: string) {
    try {
      const path = `/api/v1/chats/${channelName}/history/notify-stop-video/`;
      const data = {
        type: type,
      };
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default ChatService;
